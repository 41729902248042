.news__top::before {
  background-color: transparent;
}
a.cause-card__share {
  display: inline-flex;
}

.cause-card__bottom_event {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-card__content {
  padding: 1rem;
}

.price-one__list {
  li {
    line-height: 2rem;
  }
}

select.selectpicker {
  display: block !important;
  background: transparent;
  color: #fff;
  border: none;

  option {
    color: #000;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


.about-two__image.wow.fadeInLeft> a {position: relative;}



.about-two__image.wow.fadeInLeft> a>span {position: absolute;top: 50%;left: 50%;font-size: 48px;color: red;transform: translate(-50%, -50%);}

.about-two__image.wow.fadeInLeft> a>img {width: 100%;border-radius: 10px;}

.cause-card__content {
  padding: 40px 20px;

  .cause-card__goals {
    padding-bottom: 15px;
    margin-bottom: 14px;
}
p {
  font-size: 16px;
  margin-top: 10px;
  text-align: justify;
}
h2 {
  height: 100px;
}

}

.cause-card__content> h3 {
  height: 60px;
}

.cause-card__content >p {
  height: 77px;
}

.blog-card__content h3 {
  margin-bottom: 15px;
  padding: 0 15px;
}

.cause-card .cause-card__image {
  height: 210px;

  img{
    height: 100%;
  }
}

section.cause-details {

   .cause-card__image {
    height: auto!important;
  
    img{
      height: auto;
    }
  }

}