.faq-one {
  overflow: hidden;
  background-color: #f1f1f1;
  position: relative;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    opacity: 0.04;
    background-image: url(../images/shapes/about-count-pattern-1-1.png);
  }
  &__content {
    padding-right: 70px;
    @media (max-width: 1199px) {
      padding-right: 0;
    }
  }
  .about-counter__image-content {
    background-color: $thm-special;
    top: auto;
    bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius;
  }
  .block-title {
    margin-bottom: 0;
  }

  #accordion {
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
    li {
      padding: 25px 0;
    }
    li + li {
      border-top: 1px solid #e4e4e4;
    }
    .para-title {
      position: relative;
      color: $thm-black;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.3;
      cursor: pointer;
      padding: 0px 40px;
      transition: all 500ms ease;
      span {
        display: block;
      }

      i,
      svg {
        font-size: 16px;
        color: #7e7e7e;
        transition: all 500ms ease;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
      }
    }
    p {
      margin: 0;
      padding: 0 40px;
      color: #7e7e7e;
    }

    .active {
      .para-title {
        color: $thm-secondary;
        i,
        svg {
          color: $thm-secondary;
        }
      }

      .collapse {
        display: block;
      }
    }
  }
}
