.testimonials-one {
  background-repeat: no-repeat;
  background-position: center center;
  .block-title {
    p {
      color: $thm-base;
    }
  }
}
.testimonials-one__single {
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #fff;
  transition: all 500ms ease;
  text-align: center;
  padding-bottom: 58px;
  margin-bottom: 30px;
  &:hover {
    border-color: #e3e3e3;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  }
  p {
    width: 100%;
    margin: 0;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    color: #7e7e7e;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    color: $thm-black;
    font-size: 18px;
    font-family: $thm-font;
    font-weight: 600;
    line-height: 1;
    margin-top: 40px;
  }
  span {
    margin: 0;
    display: block;
    line-height: 1;
    font-size: 18px;
    color: $thm-secondary;
    margin-top: 5px;
  }
}

.testimonials-one__image {
  display: inline-block;
  vertical-align: middle;
  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
  position: relative;
  margin-bottom: 35px;
  img {
    border-bottom-left-radius: 38px;
    border-bottom-right-radius: 38px;
  }
  &::before {
    content: "";
    width: 38px;
    background-color: $thm-base;
    height: 38px;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    background-image: url(../images/shapes/testimonials-qoute-1-1.png);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.testimonials-two {
  padding-top: 120px;
  padding-bottom: 120px;
  .block-title p {
    color: $thm-base;
  }
  .block-title h3 {
    color: #fff;
  }
  .team-about__top-text {
    margin: 0;
    color: #9e9e9e;
    @media(max-width: 991px) {
      margin-top: 30px;
    }
  }
}

.testimonials-two__meta {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3,
  span {
    margin: 0;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: $thm-font;
    line-height: 1;
    &::after {
      content: "-";
      margin-left: 10px;
      margin-right: 10px;
      color: #fff;
    }
  }
  span {
    color: $thm-base;
    &::after {
      display: none;
    }
  }
}

#testimonials-two__carousel {
  margin-top: 50px;
  p {
    text-align: center;
    margin: 0;
    margin-bottom: 45px;
    color: #fff;
    font-size: 30px;
    line-height: 50px;
    @media(max-width: 991px) {
      br {
        display: none;
      }
    }
    @media(max-width: 575px) {
      font-size: 22px;
      line-height: 1.5;
    }
  }
}

#testimonials-two__thumb {
  width: 100%;
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
  .swiper-slide {
    padding-top: 19px;
    position: relative;
    &::before {
      content: "";
      width: 38px;
      background-color: $thm-base;
      height: 38px;
      display: block;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      background-image: url(../images/shapes/testimonials-qoute-1-1.png);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 10;
      opacity: 0;
      transition: all 500ms ease;
    }
  }
  img {
    width: 100%;
    transform: scale(0.65);
    filter: grayscale(100%);
    transform-origin: bottom center;
    transition: 500ms ease;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    cursor: pointer;
  }
  .swiper-slide-thumb-active {
    &::before {
      opacity: 1;
    }
    img {
      transform: scale(1);
      filter: grayscale(0%);
    }
  }
}


.gallery-testimonials-parallax {
  background-color: #1d1c1c;
  background-image: url(../images/backgrounds/testimonials-bg-1-1.png);
  background-position: bottom center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-blend-mode: color-dodge;
  margin-top: 175px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1d1c1c;
    opacity: 0.97;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/backgrounds/testimonials-bg-1-2.png);
    background-repeat: no-repeat;
    background-position: center -20%;
  }
  .container {
    position: relative;
    z-index: 11;
  }
  .gallery-home-one {
    top: -175px;
    position: relative;
  }
}