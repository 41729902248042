.main-menu .container {
  background-color: $thm-black;
  position: relative;
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 1200px) {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 1250px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.main-menu,
.stricky-header {
  .main-menu__list,
  .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
  }

  .main-menu__list > li {
    padding-top: 24.5px;
    padding-bottom: 24.5px;
  }

  .main-menu__list > li + li {
    margin-left: 35px;

    @media (min-width: 1281px) {
      margin-left: 70px;
    }
  }

  .main-menu__list > li > a {
    font-family: $thm-font;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #9e9e9e;
    transition: 500ms;
    text-transform: capitalize;
  }

  .main-menu__list > li.current > a,
  .main-menu__list > li:hover > a {
    color: #fff;
  }

  .main-menu__list > li > a::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    margin-left: 5px;
  }

  .main-menu__list > li > a:only-child::after {
    display: none;
  }

  .main-menu__list li.search-btn {
    margin-left: auto;
  }

  .main-menu__list li.search-btn i {
    font-size: 22px;
  }

  .main-menu__list li.cart-btn a {
    position: relative;
  }

  .main-menu__list li.cart-btn span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 10px;
    font-family: $thm-font;
    background-color: $thm-secondary;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    transition: background-color 500ms, color 500ms;
  }

  .main-menu__list li.cart-btn:hover span {
    background-color: #fff;
    color: $thm-black;
  }

  .main-menu__list li.cart-btn i {
    font-size: 23px;
  }

  .thm-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .thm-btn:hover {
    background-color: #fff;
    color: $thm-black;
  }

  .main-menu__list li ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 220px;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: 500ms ease;
    z-index: 99;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  }

  .main-menu__list li:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  .main-menu__list li ul li {
    flex: 1 1 100%;
    width: 100%;
    position: relative;
  }

  .main-menu__list li ul li + li {
    border-top: 1px solid RGBA($thm-black, 0.1);
  }

  .main-menu__list li ul li a {
    font-size: 16px;
    line-height: 30px;
    color: $thm-black;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 500ms;
  }

  .main-menu__list li ul li:last-child > a {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .main-menu__list li ul li:hover > a {
    background-color: $thm-secondary;
    color: #fff;
  }

  .main-menu__list li ul li > ul {
    top: 0;
    left: 100%;
  }

  .main-menu__list li ul li > ul.right-align {
    top: 0;
    left: auto;
    right: 100%;
  }

  /* after third level no menu */
  .main-menu__list li ul li > ul ul {
    display: none;
  }
}

.main-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;

  @media (max-width: 1199px) {
    background-color: #fff;
    position: relative;
  }

  .inner-container {
    background-color: #fff;
    border-bottom-right-radius: 10px;
    position: relative;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      width: 100000px;
      background-color: #fff;
      border-bottom-right-radius: 10px;
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  .logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0px;

    @media (min-width: 1200px) {
      padding-right: 0;
      padding-top: 55px;
    }
  }

  .mobile-nav__toggler {
    font-size: 20px;
    color: $thm-black;
    cursor: pointer;
    transition: 500ms;

    @media (min-width: 1200px) {
      display: none;
    }

    &:hover {
      color: $thm-secondary;
    }
  }
}

.main-header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;

  p {
    margin: 0;
    font-size: 14px;
    color: #7e7e7e;
    font-weight: 500;
  }
}

.main-header__social {
  display: flex;
  align-items: center;

  a {
    color: #7e7e7e;
    font-size: 16px;
    font-weight: 500;
    transition: 500ms;

    + a {
      margin-left: 30px;
    }

    &:hover {
      color: $thm-secondary;
    }
  }
}

.main-header__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 40px;
  padding-top: 16.5px;
  padding-bottom: 16.5px;

  &-list {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    li {
      color: $thm-black;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      transition: 500ms;

      &:hover {
        color: $thm-base;

        > i {
          background-color: $thm-black;
        }
      }

      + li {
        margin-left: 30px;
      }

      a {
        color: inherit;
      }

      > i {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $thm-base;
        font-size: 16px;
        margin-right: 10px;
        transition: 500ms;
      }
    }
  }
}

.main-header__language {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-image {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    margin-right: 7px;
  }

  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 70px;
  }

  .bootstrap-select > .dropdown-toggle {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: #7e7e7e;
    font-size: 14px;
  }

  .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    color: #7e7e7e;
  }
}

.main-menu {
  background-color: $thm-black;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom-left-radius: 39.5px;
  border-top-left-radius: 39.5px;
}

.stricky-header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $thm-black;
  display: none;
  transform: translateY(-110%);
  transition: transform 500ms ease;

  &.stricky-fixed {
    transform: translateY(0);
  }

  @media (min-width: 1200px) {
    display: block;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-menu__list li.search-btn,
  .stricky-header .main-menu__list li.search-btn {
    margin-left: 70px;
  }
}

/* main header two */

.main-header__two {
  .main-header__top {
    background-color: #242323;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 575px) {
      display: none;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .main-header__social a,
    p {
      color: #9e9e9e;
    }

    .main-header__social a:hover {
      color: #fff;
    }

    .main-header__language {
      margin-right: 20px;
      margin-left: auto;
    }

    .main-header__language
      .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 35px;
    }

    .main-header__language .bootstrap-select > .dropdown-toggle {
      color: #fff;
      box-shadow: none;
      outline: none;
      border: none;
    }
  }

  .header-upper {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 1199px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mobile-nav__toggler {
      font-size: 20px;
      color: $thm-black;
      cursor: pointer;
      transition: 500ms;

      @media (min-width: 1200px) {
        display: none;
      }

      &:hover {
        color: $thm-secondary;
      }
    }

    .logo-box {
      @media (max-width: 1199px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .header-info {
    display: flex;
    align-items: center;

    @media (max-width: 1199px) {
      display: none;
    }

    &__box {
      display: flex;
      align-items: center;
      padding-top: 3.5px;
      padding-bottom: 3.5px;

      > i {
        flex-shrink: 0;
        font-size: 38px;
        color: $thm-secondary;
        margin-right: 20px;
      }

      h3 {
        margin: 0;
        font-family: $thm-font;
        color: #7e7e7e;
        font-weight: 500;
        font-size: 13px;
        line-height: 1;
      }

      p {
        margin: 0;
        color: $thm-black;
        font-size: 15px;
        font-weight: 600;
        line-height: 1;
        margin-top: 10px;

        a {
          color: inherit;
          transition: all 500ms ease;

          &:hover {
            color: $thm-base;
          }
        }
      }
    }

    .header-info__box + .header-info__box {
      border-left: 1px solid #e4e4e4;
      margin-left: 40px;
      padding-left: 40px;
    }
  }

  .main-menu {
    padding-left: 0;
    padding-right: 0;
    background-color: rgba(0, 0, 0, 0);

    .container {
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: -39.5px;
      position: relative;
      z-index: 11;
      padding-left: 15px;
      padding-right: 15px;
    }

    &__list {
      background-color: $thm-black;
      width: calc(100% - 223px);
      padding-left: 60px;
      padding-right: 50px;
    }

    .thm-btn {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 10px;
      font-size: 18px;
      padding-left: 60.5px;
      padding-right: 60.5px;

      &:hover {
        background-color: $thm-primary;
        color: #fff;
      }
    }
  }

  .main-menu__list {
    border-bottom-left-radius: 39.5px;
    border-top-left-radius: 39.5px;
  }
}

.stricky-header-two {
  .thm-btn {
    display: none;
  }
}

.main-menu {
  @media screen and (max-width: $md) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11111;
    width: 280px;
    height: 100vh;
    background-color: #00000095 !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -280px;
    transition: .4s all ease-in-out;

    &.active {
      margin-left: 0;
    }

    .container {
      width: 100%;
      height: 100%;
    }

    ul.main-menu__list {
      width: 100% !important;
      height: 100%;
      background: none !important;
      flex-direction: column;
      padding: 0 !important;
      margin-top: 40px !important;
    }

    ul.main-menu__list > li {
      width: 100%;
      margin: 0 !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ul.main-menu__list > li > a {
      padding: 10px;
    }
  }
}

.main-header__two .main-menu .thm-btn {
  @media screen and (max-width: $md) {
    position: absolute;
    bottom: 50px;
    top: unset;
    right: 0;
    height: 86px;
  }
}
