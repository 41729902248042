.about-one {
  .container {
    position: relative;
  }

  [class*="col-"] {
    .img-fluid {
      border-radius: $border-radius;
      box-shadow: 0px 10px 0px 0px $thm-base;
      @media (max-width: 991px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
  [class*="col-"]:nth-child(3) {
    .img-fluid {
      box-shadow: 0px 10px 0px 0px $thm-primary;
    }
  }

  .team-about__top-text {
    padding-right: 0;
  }
}

.about-one__award {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-color: $thm-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  border-radius: 50%;
}

.about-counter {
  overflow: hidden;
  background-color: #f1f1f1;
  position: relative;
  z-index: 10;
  &::after {
    content: "";
    width: 100%;
    height: 120px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.04;
    background-repeat: no-repeat;
    background-image: url(../images/shapes/about-count-pattern-1-1.png);
  }
  .container {
    position: relative;
    z-index: 11;
  }
  &__text {
    margin: 0;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
    color: #7e7e7e;
    @media (max-width: 480px) {
      br {
        display: none;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 10;

    @media (max-width: 991px) {
      margin-top: 60px;
    }
    > img {
      border-radius: $border-radius;
      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
    &-content {
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
      max-width: 399px;
      background-color: $thm-primary;
      border-top-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 43px 50px;
      @media (max-width: 991px) {
        left: 0;
      }
      @media (max-width: 991px) {
        position: relative;
        top: auto;
        left: auto;
        border-radius: $border-radius;
        margin-bottom: 30px;
      }
      @media (max-width: 375px) {
        flex-direction: column;
        text-align: center;
      }
      img {
        flex-shrink: 0;
      }
      p {
        margin: 0;
        margin-left: 30px;
        color: #fff;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        @media (max-width: 375px) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
  .ul-list-one {
    margin-top: 45px;
    margin-bottom: 95px;
    li + li {
      margin-top: 7px;
    }
  }
}

.about-counter__count {
  background-color: #fff;
  border-radius: $border-radius;
  width: 100%;
  max-width: 470px;
  padding-top: 39px;
  padding-bottom: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -10px 60px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
  }
  h3 {
    margin: 0;
    font-size: 60px;
    font-family: $heading-font !important;
    color: $thm-secondary;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    color: #7e7e7e;
    font-weight: 500;
    margin: 0;
    margin-left: 20px;
    @media (max-width: 480px) {
      margin-left: 0;
      margin-top: 10px;
      br {
        display: none;
      }
    }
  }
}

.about-two {
  &__image {
    position: relative;
    left: -40px;
    @media (max-width: 1199px) {
      left: auto;
      margin-bottom: 60px;
      img {
        max-width: 100%;
      }
    }
    &::before {
      content: "";
      width: 254px;
      height: 247px;
      background-image: url(../images/shapes/video-dot-1-1.png);
      background-repeat: no-repeat;
      position: absolute;
      top: 60px;
      left: -145px;
      z-index: -1;
      -webkit-animation-name: shapeMover;
      animation-name: shapeMover;
      -webkit-animation-duration: 9s;
      animation-duration: 9s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
  &__award {
    position: absolute;
    bottom: 85px;
    left: 0%;
    transform: translateX(-23%);
    width: 184px;
    height: 184px;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    background-color: $thm-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
    border-radius: 50%;
    @media (max-width: 575px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__content {
    > p {
      font-size: 18px;
      line-height: 34px;
      font-weight: 500;
      color: #7e7e7e;
    }
  }
  &__box {
    padding-top: 10px;
    h3 {
      margin: 0;
      line-height: 1;
      display: flex;
      font-size: 18px;
      color: $thm-black;
      font-weight: bold;
      align-items: center;
      margin-bottom: 10px;
      i,
      svg {
        color: $thm-secondary;
        font-size: 26px;
        margin-right: 10px;
        transform: rotate(0);
        transition: transform 500ms ease;
      }
      &:hover {
        i,
        svg {
          transform: rotate(360deg);
        }
      }
    }
    p {
      margin: 0;
      font-size: 18px;
      line-height: 30px;
      color: #7e7e7e;
    }
  }
  &__box + &__box {
    margin-top: 30px;
  }
  &__box-two {
    background-color: #f0f0f0;
    padding: 60px;
    border-radius: $border-radius;
    @media (max-width: 767px) {
      max-width: 370px;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    > i,
    svg {
      font-size: 58px;
      color: $thm-secondary;
    }
    h3 {
      margin: 0;
      color: $thm-black;
      font-weight: bold;
      margin-top: 5px;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.04em;
    }
  }
  .thm-btn {
    margin-top: 15px;
    &:hover {
      background-color: $thm-black;
      color: #fff;
    }
  }
}
