.blog-sidebar {
  margin-bottom: 80px;
}
.blog-sidebar__single {
  background-color: #f1f1f1;
  border-radius: $border-radius;
  padding: 35px;
  margin-top: 30px;
  @media (min-width: 768px) {
    padding: 50px;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: $thm-black;
    line-height: 1;
    margin-bottom: 30px;
  }
  .list-unstyled {
    margin-bottom: 0;
  }
}

.blog-sidebar__search {
  form {
    width: 100%;
    position: relative;
    input {
      width: 100%;
      height: 82px;
      border-radius: $border-radius;
      border: none;
      outline: none;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      padding-left: 50px;
      background-color: $thm-secondary;
    }
    button[type="submit"] {
      border: none;
      outline: none;
      width: auto;
      padding: 0;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 50px;
      font-size: 16px;
      background-color: transparent;
      transform: translateY(-50%);
    }
    ::placeholder {
      opacity: 1;
      color: #fff;
    }
  }
}

.blog-sidebar__post {
  margin-top: -20px;
  margin-bottom: 0;
  li {
    position: relative;
    padding-left: 80px;
    min-height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    &::before {
      content: "";
      border-radius: $border-radius;
      background-color: #fff;
      width: calc(100% + 70px);
      height: 100%;
      position: absolute;
      top: 0;
      left: -35px;
      opacity: 0;
      visibility: hidden;
      transition: 500ms;
    }
    &:hover::before {
      opacity: 1;
      visibility: visible;
    }
    h3 {
      margin-bottom: 0;
      font-size: 16px;
      position: relative;
      font-weight: bold;
      font-family: $heading-font;
      color: #7e7e7e;
      line-height: 26px;
      z-index: 10;
    }
    a {
      color: inherit;
      transition: 500ms;
      &:hover {
        color: $thm-secondary;
      }
    }
    > img {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 10;
      transform: translateY(-50%);
    }
  }
}

.blog-sidebar__category {
  margin-bottom: 0px;
  position: relative;
  bottom: -15px;
  margin-top: -30px;
  li {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      border-radius: $border-radius;
      left: -35px;
      top: 0;
      height: 100%;
      width: calc(100% + 70px);
      background-color: #fff;
      opacity: 0;
      transition: 500ms ease;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      a {
        color: $thm-secondary;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #7e7e7e;
      font-size: 18px;
      position: relative;
      line-height: 42px;
      transition: 500ms ease;
      &::after {
        content: "\f105";
        font-weight: 400;
        font-family: "Font Awesome 5 Pro";
      }
    }
  }
}

.blog-sidebar__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;
  li {
    color: #7e7e7e;
    font-size: 18px;
    line-height: 1;
    margin-top: 15px;
    a {
      color: inherit;
      transition: 500ms;
      &:hover {
        color: $thm-base;
      }
    }
  }
  li:not(:last-of-type)::after {
    content: ",";
    margin-right: 5px;
  }
}

.blog-sidebar__comments {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: -5px;
  li {
    position: relative;
    padding-left: 60px;
    color: #7e7e7e;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    + li {
      margin-top: 20px;
    }
    a {
      color: inherit;
      transition: 500ms;
      &:hover {
        color: $thm-black;
      }
    }
    &::before {
      content: "\f075";
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      background-color: $thm-special;
      width: 44px;
      height: 44px;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.blog-card__content p {
  margin-bottom: 10px !important;
}

.blog-card__image {
  height: 250px;
}