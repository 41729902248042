.main-slider {
  overflow: hidden;
  background-color: #151414;
  text-align: left;
  z-index: 1;

  .slick-prev {
    left: 25px;
    z-index: 1;
  }

  .slick-next {
    right: 25px;
    z-index: 1;
  }
  .main-slider-item {
    position: relative;
  }

  .image-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1);
    transition: transform 7000ms ease;
    img {
      width: 100%;
      height: 100%;
      z-index: -2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #151414;
      opacity: 0.8;
      z-index: 1;
    }
  }

  .container {
    padding-top: 370px;
    padding-bottom: 180px;
    @media (max-width: 1199px) {
      padding-top: 180px;
    }
    @media (max-width: 575px) {
      padding-bottom: 120px;
      padding-top: 120px;
    }
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: $thm-base;
    opacity: 0;
    transform: translateY(-120px);
    transition-delay: 0;
    transition: transform 2000ms ease, opacity 2000ms ease;
  }
  h2 {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 100px;
    line-height: 1.1;
    font-weight: bold;
    letter-spacing: -0.04em;
    opacity: 0;
    visibility: hidden;
    transform: translateY(90px);
    transition-delay: 1000ms;
    transition: transform 2000ms ease, opacity 2000ms ease;

    @media (max-width: 1199px) {
      font-size: 80px;
    }
    @media (max-width: 991px) {
      font-size: 60px;
      line-height: 1.3;
    }
    @media (max-width: 575px) {
      font-size: 50px;
    }
    @media (max-width: 375px) {
      font-size: 37px;
    }
  }
  .thm-btn {
    opacity: 0;
    transform: translateY(100px);
    transition-delay: 2500ms;
    transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
      background 500ms ease;
  }

  .slick-slide.slick-active.slick-current {
    .image-layer {
      transform: scale(1.15);
    }
    .thm-btn,
    h2,
    p {
      visibility: visible;
      opacity: 1;
      transform: translateY(0) translateX(0);
    }
  }
}

#main-slider-pagination {
  z-index: 10;
  bottom: auto;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1170px;
  transform: translate(-50%, calc(-50% + 95px));
  text-align: left;
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) {
    max-width: 960px;
    transform: translate(-50%, calc(-50% + 40px));
  }
  @media (max-width: 575px) {
    display: none;
  }
  .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
    border-radius: 0;
    border-top-right-radius: 8.5px;
    border-bottom-right-radius: 8.5px;
    opacity: 0.4;
    background-color: #fff;
    + .swiper-pagination-bullet {
      margin-top: 10px;
    }
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

.main-slider__two {
  .container {
    padding-top: 250px;
    padding-bottom: 170px;
    @media (max-width: 1199px) {
      padding-top: 160px;
      padding-bottom: 160px;
    }
    @media (max-width: 575px) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
  h2 {
    font-size: 70px;
    line-height: 80px;
    letter-spacing: -0.04em;
    color: #fff;
    margin: 0;
    span {
      position: relative;
      &::before {
        content: "";
        width: 58px;
        height: 50px;
        background-image: url(../images/main-slider/heart-1-1.png);
        position: absolute;
        bottom: 70%;
        left: 50%;
        transform: translateX(-50%);
        background-size: 100%;
        background-repeat: no-repeat;
        @media (max-width: 375px) {
          width: 40px;
          height: 35px;
        }
      }
    }

    @media (max-width: 1199px) {
      font-size: 60px;
    }
    @media (max-width: 991px) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media (max-width: 575px) {
      font-size: 45px;
    }
    @media (max-width: 375px) {
      font-size: 30px;
    }
  }
  p {
    margin: 0;
    color: #fff;
    transform: translateY(120px);
    margin-top: 25px;
    margin-bottom: 40px;
  }
  #main-slider-pagination {
    left: auto;
    right: 50%;
    text-align: right;
    align-items: flex-end;
    transform: translate(50%, calc(-50% + 0px));
  }
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

button.slick-arrow.slick-next,
button.slick-arrow.slick-prev {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  height: 28px;
  width: 28px;
}
button.slick-arrow.slick-next {
  transform: rotate(-50deg);
}
button.slick-arrow.slick-prev {
  transform: rotate(140deg);
}
