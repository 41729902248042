.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 911;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-110%);
  transition: transform 500ms ease, opacity 500ms ease;
}

.search-popup.active {
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $thm-black;
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: $border-radius;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}
.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  &:hover {
    background-color: $thm-black;
    color: #fff;
  }
}
.search-popup__content .thm-btn i {
  margin: 0;
}

