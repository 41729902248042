.main-footer {
  background-color: $thm-black;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/backgrounds/footer-bg-1-1.png);
    opacity: 0.5;
  }

  .container {
    position: relative;
  }
}

.footer-bottom {
  background-color: $thm-black2;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 38px;
    position: relative;

    @media (max-width: 575px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: $thm-text-dark;
    }

    .scroll-to-top {
      display: flex;
      width: 68px;
      height: 54px;
      @include reverseHover($bgColor: $thm-black2, $color: #fff);
      border-top-left-radius: 34px;
      border-top-right-radius: 34px;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 500ms ease;
    }
  }
}

.footer-social {
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    margin-top: 10px;
  }

  a {
    + a {
      margin-left: 10px;
    }

    @include socialIcon($bgColor: $thm-secondary, $color: #fff);

    &:nth-child(2) {
      @include reverseHover($bgColor: $thm-special, $color: #fff);
    }

    &:nth-child(3) {
      @include reverseHover($bgColor: $thm-base, $color: #fff);
    }

    &:nth-child(4) {
      @include reverseHover($bgColor: $thm-primary, $color: #fff);
    }
  }

  &.black-hover a:hover {
    background-color: $thm-black !important;
    color: #fff !important;
  }
}

.footer-widget {
  p {
    margin: 0;
    color: $thm-text-dark;
  }

  &__about {
    p {
      margin-top: 35px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
    }
  }

  &__contact {
    li {
      position: relative;

      a {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        transition: 500ms;
        position: relative;
        padding-left: 28px;

        &:hover {
          color: $thm-primary;
        }

        > i {
          font-size: 15px;
          color: $thm-secondary;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__logo {
    display: block;
    margin-top: -22px;
    width: 200px;
    margin: 0 auto;
  }

  &__title {
    margin: 0;
    color: #fff;
    font-size: $heading-size;
    font-weight: bold;
    margin-bottom: 37px;
  }

  &__link-list {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    li {
      flex: 1 1 50%;

      a {
        color: $thm-text-dark;
        font-size: 16px;
        transition: 500ms;
        position: relative;
        line-height: 40px;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          display: block;
          transition: transform 500ms;
          transform: scale(0, 1);
          background-color: $thm-base;
          transform-origin: left;
        }

        &:hover {
          color: $thm-base;

          &::before {
            transform: scale(1, 1);
            transform-origin: right;
          }
        }
      }
    }
  }

  &__blog {
    li {
      position: relative;
      padding-left: 90px;
      min-height: 70px;

      + li {
        margin-top: 40px;
      }

      p {
        margin: 0;
        color: $thm-base;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 7px;
      }

      h3 {
        font-size: 18px;
        line-height: 1.44;
        margin: 0;
        color: #fff;
        font-family: $thm-font;
        font-weight: 600;

        a {
          color: inherit;
          transition: 500ms;

          &:hover {
            color: $thm-primary;
          }
        }
      }

      > img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius;
      }
    }
  }

  &__newsletter {
    p {
      margin-top: -10px;
    }

    input {
      width: 100%;
      height: 63px;
      background-color: $thm-black2;
      border: 1px solid rgba(#fff, 0.1);
      color: $thm-text-dark;
      font-size: 14px;
      font-weight: 500;
      padding-left: 39px;
      margin-top: 16px;
      margin-bottom: 10px;
      border-bottom-left-radius: 31.5px;
      border-top-left-radius: 31.5px;
    }

    .mc-form__response {
      a {
        color: #fff;
        transition: all 500ms ease;

        &:hover {
          color: $thm-base;
        }
      }
    }
  }
}
