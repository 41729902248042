.google-map__home {
    width: 100%;

    iframe {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        height: 637px;
    }
}

.google-map__contact {
    width: 100%;
    iframe {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        height: 497px;
    }
}
.google-map__event {
    width: 100%;
    iframe {
        display: block;
        border: none;
        outline: none;
        width: 100%;
        height: 440px;
        border-radius: $border-radius;
    }
}