.causes-col__3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.cause-card {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  transition: all 500ms ease;
  border-radius: $border-radius;

  &:hover {
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  }
}

.cause-card__inner {
  overflow: hidden;
  border-radius: $border-radius;
  background-color: #fff;
  transition: all 500ms ease;
}

.cause-card__image {
  overflow: hidden;
  background-color: $thm-black;

  >img {
    width: 100%;
    transform: scale(1);
    transition: transform 500ms ease, opacity 500ms ease;
  }
}

.cause-card:hover .cause-card__image>img {
  opacity: 0.5;
  transform: scale(1.05);
}

.cause-card__content {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 66px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transition: all 500ms ease;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  h3 {
    margin: 0;
    color: $thm-black;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.416;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    a {
      color: inherit;
      transition: 500ms ease;

      &:hover {
        color: $thm-secondary;
      }
    }
  }

  p {
    margin: 0;
    margin-top: 30px;
    color: #7e7e7e;
    margin-bottom: 20px;
  }

  .thm-btn {
    padding: 12.5px 24px;
  }

  .thm-btn:hover {
    background-color: $thm-black;
    color: #fff;
  }
}

.cause-card:hover .cause-card__content {
  border-color: #e4e4e4;
}

.cause-card__bottom {
  display: flex;
  align-items: center;
}

.cause-card__share {
  width: 53px;
  height: 53px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 500ms;
  border-radius: 50%;
  margin-left: 20px;

  >i {
    color: #7e7e7e;
    font-size: 20px;
    transition: 500ms;
  }

  &:hover {
    background-color: $thm-black;

    >i {
      color: #fff;
    }
  }
}

.cause-card__goals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 30px;
  margin-bottom: 23px;

  >p {
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #7e7e7e;

    strong {
      font-weight: 400;
      color: $thm-black;
    }
  }
}

.cause-card__progress {
  width: 100%;
  height: 9px;
  position: relative;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 15px;

  >span {
    border-radius: 5px;
    background-color: $thm-primary;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    >b {
      border-radius: 5px;
      background-color: inherit;
      bottom: 100%;
      left: 100%;
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      padding-left: 11px;
      padding-right: 11px;
      line-height: 25px;
      margin-bottom: 10px;
      border-bottom-left-radius: 0;
      display: flex;

      i {
        font-style: normal;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: -3px;
        border: 5px solid transparent;
        border-top: 5px solid $thm-primary;
        border-left: 5px solid $thm-primary;
      }
    }
  }
}

.cardProgress {
  animation-name: cardProgress;
  transform-origin: left center;
}

@keyframes cardProgress {
  0% {
    transform: scale(0, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.swiper-slide:nth-child(1) .cause-card,
.cause-card:nth-child(1) {
  .cause-card__progress>span {
    background-color: $thm-secondary;
  }

  .cause-card__progress>span>b::after {
    border-top-color: $thm-secondary;
    border-left-color: $thm-secondary;
  }
}

.swiper-slide:nth-child(2) .cause-card,
.cause-card:nth-child(2) {
  .cause-card__progress>span {
    background-color: $thm-primary;
  }

  .cause-card__progress>span>b::after {
    border-top-color: $thm-primary;
    border-left-color: $thm-primary;
  }
}

.swiper-slide:nth-child(3) .cause-card,
.cause-card:nth-child(3) {
  .cause-card__progress>span {
    background-color: $thm-special;
  }

  .cause-card__progress>span>b::after {
    border-top-color: $thm-special;
    border-left-color: $thm-special;
  }
}

.swiper-slide:nth-child(4) .cause-card,
.cause-card:nth-child(4) {
  .cause-card__progress>span {
    background-color: #4bb2ed;
  }

  .cause-card__progress>span>b::after {
    border-top-color: #4bb2ed;
    border-left-color: #4bb2ed;
  }
}

.swiper-slide:nth-child(5) .cause-card,
.cause-card:nth-child(5) {
  .cause-card__progress>span {
    background-color: #c94bed;
  }

  .cause-card__progress>span>b::after {
    border-top-color: #c94bed;
    border-left-color: #c94bed;
  }
}

.swiper-slide:nth-child(6) .cause-card,
.cause-card:nth-child(6) {
  .cause-card__progress>span {
    background-color: #ff7916;
  }

  .cause-card__progress>span>b::after {
    border-top-color: #ff7916;
    border-left-color: #ff7916;
  }
}

.causes-home {
  .block-title {
    margin-bottom: 0;
  }
}

.causes-page {
  .swiper-container {
    overflow: visible;

    @media (max-width: 1199px) {
      overflow: hidden;
    }
  }
}

.featured-cause {
  .inner-container {
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url(../images/shapes/featured-cause-bg-1-1.png);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding-right: 60px;
    overflow: hidden;

    @media (max-width: 991px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media(max-width: 575px) {
      padding-left: 0;
      padding-right: 0;
    }

    img {
      @media (max-width: 991px) {
        display: none;
      }

      @media (max-width: 575px) {
        display: block;
        width: 100%;
      }
    }
  }

  .my-auto {
    width: 100%;
    padding-left: 20px;

    @media (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 0;
    }

    @media(max-width: 575px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  h3 {
    margin: 0;
    font-weight: 700;
    color: #fff;
    font-size: 30px;
    letter-spacing: -0.04em;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: #fcdede;
    margin-top: 5px;
  }

  .cause-card__top {
    margin-top: 45px;

    @media (max-width: 1199px) {
      margin-top: 24px;
    }
  }

  .cause-card__progress {
    height: 15px;
    border-radius: 7.5px;
    background-color: #fff;
  }

  .cause-card__progress>span {
    background-color: $thm-base;

    >b::after {
      border-top-color: $thm-base;
      border-left-color: $thm-base;
    }
  }

  .cause-card__goals {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    p {
      font-size: 18px;
      font-weight: 600;
      color: #fff;

      strong {
        color: #fff;
        font-weight: 400;
      }
    }
  }
}