.price-one {
  background-color: #242323;
  padding-bottom: 120px;
  padding-top: 265px;
  background-image: url(../images/backgrounds/price-bg-1-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  .block-title {
    p {
      color: $thm-base;
    }
    h3 {
      color: #fff;
    }
  }
  &__main {
    > p {
      margin: 0;
      color: #9e9e9e;
      font-size: 18px;
      line-height: 34px;
      font-weight: 500;
      margin-bottom: 50px;
    }
    @media (max-width: 1199px) {
      margin-bottom: 60px;
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  &__image-box {
    display: flex;
    @media (max-width: 425px) {
      flex-direction: column;
    }

    img {
      flex-shrink: 0;
      margin-right: 30px;
      border-radius: $border-radius;

      @media (max-width: 425px) {
        margin-right: 0;
        margin-bottom: 30px;
        flex-shrink: none;
      }
    }
    h3 {
      display: flex;
      color: #fff;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.04em;
      font-weight: bold;
      align-items: center;
      i,
      svg {
        margin-right: 20px;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $thm-secondary;
        padding: 12px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 16px;
        transform: rotate(0deg);
        transition: transform 500ms ease;
      }
      &:hover {
        i,
        svg {
          background-color: $thm-base;

          transform: rotate(360deg);
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      color: #9e9e9e;
      margin: 0;
    }
  }

  &__single {
    background-image: url(../images/shapes/price-line-1-1.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-color: #ffffff;
    border-radius: $border-radius;
    text-align: center;
    transition: all 500ms ease;
    padding-bottom: 60px;
    * {
      transition: all 500ms ease;
    }
    @media (max-width: 767px) {
      margin-top: 30px;
    }
    > i,
    svg {
      width: 30px;
      height: 33px;
      padding: 20px;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 30px;
      margin-left: auto;
      margin-right: auto;

      background-color: $thm-primary;
      color: #fff;

      &.fa-plane::before {
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    }
    p {
      margin: 0;
      color: $thm-secondary;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    h3 {
      margin: 0;
      font-size: 36px;
      font-weight: bold;
      color: $thm-black;
    }

    a.thm-btn {
      font-size: 14px;
      padding: 13.5px 30px;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(#fff, 0.1);
    border-bottom: 1px solid rgba(#fff, 0.1);
    list-style: none;
    max-width: 200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-color: #e4e4e4;
    li {
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
      color: #9e9e9e;
      color: #7e7e7e;
      + li {
        margin-top: 25px;
      }
    }
  }
  &__single:hover {
    background-color: #1d1c1c;
    > i,
    svg {
      background-color: #242323;
      color: $thm-primary;
    }
    h3 {
      color: #fff;
    }
    .price-one__list {
      border-color: rgba(#fff, 0.1);
    }
    a.thm-btn:hover {
      background-color: #fff;
      color: $thm-black;
    }
  }
}
