.video-card {
  background-color: $thm-black;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: grayscale(100%);
    opacity: 0.2;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .container {
    position: relative;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $thm-base;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      line-height: 1;
      margin-bottom: 15px;

      img {
        margin-right: 10px;
      }
    }

    h3 {
      margin: 0;
      color: #fff;
      letter-spacing: $letter-space;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 45px;

      @media (max-width: 575px) {
        font-size: 40px;

        br {
          display: none;
        }
      }

      @media (max-width: 375px) {
        font-size: 30px;
      }
    }
  }

  &__btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-left: 30px;
    @include reverseHover($bgColor: $thm-primary, $color: #fff);
  }
}

.video-card-two {
  padding-top: 100px;
  position: relative;
  z-index: 11;

  .inner-container {
    background-position: top right;
    background-repeat: no-repeat;
    background-color: #242323;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 65px;
    padding-bottom: 20px;
    position: relative;
    z-index: 11;
    border-bottom-left-radius: 145.5px;
    border-top-left-radius: 145.5px;

    @media (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 30px;
      text-align: center;
      padding-right: 20px;

      border-radius: $border-radius !important;
    }
  }

  h3 {
    margin: 0;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -0.05em;
    font-weight: bold;
    color: #fff;
    padding-left: 22px;
    position: relative;
    left: 30px;

    @media (max-width: 1199px) {
      left: 0;
      font-size: 30px;
      line-height: 1.5;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 0;
    }
  }

  p {
    margin: 0;
    padding-left: 70px;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
    color: #9e9e9e;

    @media (max-width: 1199px) {
      padding-left: 0;
    }
  }

  &__box {
    display: inline-block;
    position: relative;

    @media (max-width: 991px) {
      width: 251px;
      height: 251px;
      margin-left: auto;
      display: block;
      margin-right: auto;
    }

    >img {
      border-radius: 50%;

      @media (max-width: 1199px) {
        max-width: 100%;
      }
    }

    &-btn {
      width: 56px;
      height: 56px;
      background-color: $thm-base;
      color: #fff;
      display: flex;
      align-items: center;
      font-size: 12px;
      justify-content: center;
      transition: 500ms ease;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) translateX(50%);

      &:hover {
        color: #fff;
        background-color: $thm-primary;
      }

      @media (max-width: 575px) {
        top: 50%;
        right: auto;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}