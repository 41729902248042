.registration-form {
  font-family: $thm-font;
  &-header {
    background-color: #43c7f0;
    padding: 60px 0 90px;
    color: #fff;

    & > .container {
      display: grid;
      grid-template-columns: 25% 50% 25%;

      @media screen and (max-width: $sm) {
        grid-template-columns: 100%;
      }
      @media screen and (max-width: $xs) {
        grid-template-columns: 100%;
      }
    }

    &-logo {
      img {
        width: 200px;
      }
    }

    &-body {
      text-align: left;

      @media screen and (max-width: $sm) {
        order: 1;
        margin-top: 40px;
        text-align: center;
      }

      @media screen and (max-width: $xs) {
        order: unset;
        width: 100%;
      }

      .title {
        h2 {
          color: #fff;
          font-size: 48px;
          font-family: $heading-font;
          font-weight: 900;
        }
      }
      p {
        font-size: 24px;
        font-weight: 600;
        margin: 0 !important;
      }
    }
  }

  &-image {
    img {
      width: 200px;
    }
  }

  //   body style

  &-body {
    position: relative;

    .title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border: 4px solid $thm-color;
      font-size: 34px;
      padding: 15px;
      font-weight: 800;
      font-family: $heading-font;
    }
  }

  .registration {
    padding: 80px 0 40px;
    text-align: left;

    .date-label {
      font-size: 24px;
      color: #3d3d3c;
      margin-top: 10px;
      padding-right: 10px;
    }

    .date {
      width: 200px;
    }

    input,
    select {
      border: 2px solid #7f8482;
      border-width: 0 0 2px;
      outline: none;
      font-size: 24px;
      color: #3d3d3c;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      background-color: transparent;

      &::placeholder {
        font-size: 24px;
        color: #3d3d3c7e;
      }
    }

    .radio-wrp {
      color: #3d3d3c;
      font-size: 24px;
      padding-top: 10px;

      span {
        padding-right: 20px;
        display: inline-block;
        // width: 195px;
      }

      .radio-group {
        padding: 0 15px;
        // width: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $xs) {
          display: block;
        }

        label {
          padding-right: 10px;
          cursor: pointer;
        }

        input {
          width: auto;
          height: auto;
          visibility: hidden;
          position: relative;
          cursor: pointer;
          -moz-appearance:initial;
          
          

          &::after {
            content: "";
            width: 20px;
            height: 20px;
            border: 4px solid #7f8482;
            position: absolute;
            top: 20%;
            left: 5px;
            transform: translate(-50%, -50%);
            visibility: visible;
            border-radius: 0;
          }

          &::before {
            content: "";
            width: 15px;
            height: 15px;
            background-color: #43c7f0;
            position: absolute;
            top: 20%;
            left: 5px;
            transform: translate(-50%, -50%);
            border-radius: 0;
          }

          &:checked {

            &::before {
              visibility: visible;
            }
          }
        }
      }
    }

    &-personal {
      margin: 50px 0;
      border: 4px solid;
      position: relative;
      padding: 30px 10px 10px;

      .group-title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #43c7f0;

        h2 {
          font-size: 24px;
          text-transform: uppercase;
          font-weight: 800;
          margin: 0;
          padding: 10px 20px;
          color: #fff;
        }
      }

      .radio-wrp {
        .error-message {
          color: rgb(126, 22, 22);
            font-size: 10px;
        }
      }
      .input-grid{
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $xs) {
          flex-direction: column;
        }
        
        .input-item {

          width: 100%;
          position: relative;
          padding-bottom: 5px;
          span {
            display: none;
          }
        } 

        .input-item.error {
          
          
          span {
            color: rgb(126, 22, 22);
            font-size: 10px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -12px;
            display: block;
          }

          input, select, label {
            color: rgb(126, 22, 22);
            border-color: rgb(126, 22, 22);
          }
        }

        input {
          width: 100%;
        }
      }
    }

    .thm-btn {
      display: block;
      margin-left: auto;
      width: 300px;
      border-radius: 1rem;
      font-size: 24px;
      padding: 10px 0;
      text-transform: uppercase;
      transition: 0.4s all ease-in;
      &:hover {
        background-color: #43c7f0 !important;
        color: #fff;
      }
    }
  }

  .notic {
    color: #3d3d3c;
    font-size: 22px;
  }

  &-footer {
    min-height: 100px;
    background-color: #43c7f0;
  }
}
