.fact-counter {
  position: relative;
  margin-bottom: -145px;
  &::before {
    content: "";
    width: 485px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(../images/shapes/cta-bg-shape-1-1.png);
    background-position: right center;
    background-repeat: no-repeat;
    z-index: 12;
  }
  .container {
    padding-top: 46px;
    padding-bottom: 46px;
    position: relative;
    z-index: 11;
    padding-left: 75px;
    @media(max-width: 991px) {
      padding-left: 15px;
    }
    &::before {
      content: "";
      position: absolute;
      border-top-left-radius: 145px;
      border-bottom-left-radius: 145px;
      left: 15px;
      top: 0;
      width: 1000000px;
      height: 100%;
      background-color: $thm-secondary;
      @media (max-width: 991px) {
        border-radius: $border-radius;
      }
    }
  }
  h3 {
    font-family: $heading-font !important;
    color: #fff;
    font-size: 60px;
    line-height: 1;
    margin: 0;
    margin-top: 40px;
  }
  p {
    margin: 0;
    margin-top: 10px;
    font-size: 18px;
    color: #fff;
    line-height: 1;
    margin-bottom: 20px;
  }
  a {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $thm-base;
    margin-left: auto;
    margin-right: auto;
    width: 55px;
    height: 36px;
    border-bottom-left-radius: 27.5px;
    border-bottom-right-radius: 27.5px;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;
  }
  [class*="col-"] {
    position: relative;
    @media (max-width: 991px) {
      &:nth-child(3n) {
        &::before {
          display: none;
        }
      }
    }
    @media(max-width: 767px) {
      &::before {
        display: none;
      }
    }
  }
  [class*="col-"]:not(:first-of-type) {
    position: relative;
    &::before {
      content: "";
      width: 1px;
      height: 140px;
      background-color: #fff;
      opacity: 0.2;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  [class*="col-"]:hover {
    a {
      opacity: 1;
      visibility: visible;
    }
  }
}
