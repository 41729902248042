// profile button

.profile-btn{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 20px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
    }

    .profile-links {
        width: auto;
        height: auto;
        padding: 10px 10px;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: rgba(228, 228, 228, 0.90);
        border-radius: 4px;
        width: 200px;
        display: none;
        
        a, button {
            
            display: block;
            background-color: transparent;
            color: $thm-black!important;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
            padding: 3px 20px;
            text-align: left;
            width: 100%;
        }

        button {
            border: none;
            border-top: 1px solid #fff;

        }
    }

    &:hover {
        .profile-links {
            display: block;
        }
    }
}

// profile page 

.profile {
    position: relative;
    
    &-wrp {
        margin: 50px 0;
        text-align: left;
        color: $thm-black;
    }

    &-header {
        display: flex;
        gap: 40px;
        padding: 20px 0;
        border-bottom: 1px solid rgb(190, 190, 190);

        &-left {

            &-photo {
                width: 200px;
                height: 200px;
                border: 5px solid $thm-base;
                background-color: rgb(190, 190, 190);
                overflow: hidden;
                border-radius: 10px;
                position: relative;

                .change-photo {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color:rgba($color: #000000, $alpha: .50);
                    transition: .3s all ease;
                    transform: scaleY(0);
                    transform-origin: bottom;
                    
                    a {
                        color: #fff;
                        text-transform: capitalize;
                    }

                    
                    }
                    &:hover {
                        .change-photo {
                            transform: scaleY(1);
                        }
                }
                
               

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-right {
            strong {
                color:$thm-primary;
            }
        }
    }

    &-body {
        display: flex;
        gap: 40px;
        padding: 20px 0;

        &-left {
            width: 250px;
            
            &-menu {
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 0;

                &-item {
                    border-bottom: 1px solid rgb(212, 212, 212);
                    transition: .4s all;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.active, &:hover {
                        border-bottom: 2px solid $thm-primary;
                    }
                    

                    

                }

                &-link {
                    border: none;
                    background-color: transparent;
                    display: block
                }
            }
        }

        &-right {
            width: 100%;
        }
    }

    .profile-change-form {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 111;
        width: 380px;
        height: 300px;
        background-color: #fff;
        border: 1px solid #cecece;
        color: #000000;
        padding: 20px;
        overflow: hidden;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        &.active{
            display: flex;
        } 

        .close {
            position: absolute;
            top: 0px;
            right: 20px;
            font-size: 28px;
            cursor: pointer;
        }
        

        label {
            display: block;
            text-align: left;
            font-size: 22px;
            padding-bottom: 10px;
        }

        button {
            width: 120px;
            margin-top: 20px;
            display: block;
            border: none;
            background-color: $thm-base;
            height: 50px;
            color: #fff;
            cursor: pointer;
            border-radius: 3rem;   
            font-size: 22px;
        }
    }

}


// profile info

.profile-info {
    list-style: none;

    &-item {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        border-bottom: 1px solid rgb(190, 190, 190);
        width: 100%;
        padding: 10px 0;

        &:last-child {
            border-bottom: none;
        }

        &-name {
            width: 150px;
            display: block;
        }
    }
}